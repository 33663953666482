<!--
 * @Author: Dyf
 * @Date: 2023-11-07 14:34:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-30 13:57:05
 * @Description: 校园动态
-->
<style lang="scss" scoped>
.school-news {
  margin-top: 24px;
  @include innerPage($p: 0 10px 0 0);
  height: calc(100% - 24px);
  box-sizing: border-box;
  padding-right: 10px;
  overflow: hidden;
  overflow-y: auto;
  .banner-swiper {
    width: 100%;
    height: 448px;
    border-radius: 10px;
    background: #eff3f9;
    overflow: hidden;
    margin-bottom: 24px;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    .link .el-image {
      cursor: pointer;
    }

    .el-image {
      width: 100%;
      height: 100%;
    }

    .no-data {
      background: #fff;
      border-radius: 10px;
    }

    .banner-pagination {
      width: 100%;
      height: 64px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      @include flexBox(center);

      ::v-deep .banner-bullet {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 5px;
        margin: 0 5px;
        cursor: pointer;

        &-active {
          width: 54px;
        }
      }
    }
  }
  .box-inner {
    width: 100%;
    height: 100%;
    @include innerPage($p: 0);
    @include pageTitle(0);
    @include pageFoot;
    .page-inner {
      height: calc(100% - 46px);
      box-sizing: border-box;
      padding-top: 36px;

      .news-list {
        width: 100%;
        height: 100%;
        margin-bottom: 26px;
        flex-wrap: wrap;
        overflow: hidden;
        overflow-y: auto !important;
        @include flexBox(flex-start, flex-start);

        &--wrapper {
          width: calc(100% + 20px);
          height: calc(100% - 100px);
          margin-bottom: 26px;

          &.no-data {
            width: 100%;
            height: calc(100% - 104px);
            background: #fff;
            border-radius: 10px;
            margin: 0 auto 30px;
          }
        }

        .news-item {
          width: calc((100% - 80px) / 4);
          height: 362px;
          border-radius: 10px;
          background: #fff;
          margin-right: 20px;
          cursor: pointer;
          position: relative;

          &:nth-child(n + 5) {
            margin-top: 36px;
          }

          .el-image {
            width: 100%;
            height: 218px;
            border-radius: 10px;
          }

          &:hover {
            box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15),
              5px 5px 27px -10px rgba(108, 78, 203, 0.15);
          }
          .from {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-flex;
            align-items: center;
            background: #8275de;
            color: #fff;
            padding: 0 6px;
            height: 26px;
            border-radius: 0 10px 0 12px;
            z-index: 2;
            font-size: 12px;
          }
        }

        .news-info {
          box-sizing: border-box;
          padding: 0 28px;

          h5 {
            line-height: 24px;
            color: #3c3b3b;
            margin-top: 20px;
          }

          .desc {
            line-height: 24px;
            color: #787878;
            margin-top: 4px;
          }
        }

        .news-foot {
          height: 58px;
          border-top: 1px solid rgba($color: #585858, $alpha: 0.14);
          margin-top: 10px;
          @include flexBox;

          .iconfont {
            font-size: 18px;
            color: #666;
            margin-right: 6px;
          }

          span {
            color: #949495;
          }

          .views {
            margin: 0 20px 0 auto;
            color: #7a7a7a;

            .pf_bold {
              font-size: 22px;
              color: #6340c8;
            }
          }

          .likes {
            padding: 0;
            min-width: auto;
            color: #4a4a4a;

            .iconfont {
              font-size: 24px;
              color: #d6d8e1;
            }

            &.did .iconfont {
              color: #fc546b;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .school-news {
    margin-top: 18px;
    .banner-swiper {
      height: 360px;
    }
    .box-inner {
      .page-inner {
        height: calc(100% - 36px);
        .news-list--wrapper {
          width: calc(100% + 16px);
          height: calc(100% - 64px);
          margin-bottom: 12px;
        }
        .news-list .news-item {
          width: calc((100% - 42px) / 3);
          height: 348px;
          margin-right: 14px;
          &:nth-child(n + 4) {
            margin-top: 20px;
          }
          .news-info {
            padding: 0 14px;
            h5 {
              margin-top: 16px;
            }
          }
          .news-foot {
            height: 48px;
            .views .pf_bold {
              font-size: 18px;
            }
            .likes .iconfont {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <section class="school-news">
    <div class="banner-swiper swiper" v-loading="bannerload">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          :class="{ link: item.plban_herf }"
          v-for="item in banner_list"
          :key="item.plban_id"
        >
          <el-image
            fit="cover"
            :src="formatfile(item.plban_image)"
            @click.stop="targetLink(item)"
          />
        </div>
        <div class="swiper-slide no-data" v-if="$isEmpty(banner_list)">
          <div class="no-data--empty">
            <img src="@assets/images/no-data3.png" alt />
            <p>暂无数据哦~</p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination banner-pagination"></div>
    </div>
    <div class="box-inner">
      <div class="page-title">
        <h3>校园动态</h3>
        <div class="search-form">
          <el-form inline>
            <el-form-item>
              <el-input
                v-model.trim="query_word"
                maxlength="100"
                placeholder="输入关键字搜索"
                clearable
              />
            </el-form-item>
          </el-form>
          <el-button type="custom_primary" size="medium" @click="searchData"
            >搜索</el-button
          >
        </div>
      </div>
      <div
        class="page-inner"
        :class="{ 'no-data': $isEmpty(listData) }"
        v-loading="dataLoad"
      >
        <div
          class="news-list--wrapper"
          :class="{ 'no-data': $isEmpty(listData) }"
        >
          <ul class="news-list" v-show="!$isEmpty(listData)">
            <li
              class="news-item"
              v-for="item in listData"
              :key="item.plinf_id"
              @click="
                $router.push({
                  name: 'SCHOOLNEWS_DETAIL',
                  params: { id: item.plinf_id },
                })
              "
            >
              <p class="from">
                {{ item.plinf_type_name }}
              </p>
              <el-image fit="cover" :src="formatfile(item.plinf_image)" />
              <div class="news-info">
                <h5 class="bold line-text--1st">{{ item.plinf_title }}</h5>
                <p class="desc line-text--1st">{{ item.plinf_content }}</p>
                <div class="news-foot">
                  <i class="iconfont">&#xe61a;</i>
                  <span>{{ item.create_time | formatTime("YYYY/MM/DD") }}</span>
                  <span class="views"
                    ><b class="pf_bold">{{ item.plinf_read_num || 0 }}</b
                    >浏览</span
                  >
                  <el-button
                    type="text"
                    class="likes"
                    :class="{ did: item.is_praise == 1 }"
                    @click.stop="
                      item.is_praise = (item.is_praise == 1 && 2) || 1;
                      likeNews(item.plinf_id);
                    "
                  >
                    <i class="iconfont">&#xe641;</i>
                    <span class="light">点赞</span>
                  </el-button>
                </div>
              </div>
            </li>
          </ul>
          <div class="no-data--empty" v-if="$isEmpty(listData)">
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂无消息数据哦~</p>
          </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
          <customPagination
            :current="listPage.pageIndex"
            :total="listPage.total"
            :size="listPage.pageSize"
            @pageChange="flippingPage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as news from "@api/dynamic";
import { formatFile } from "@utils";
import customPagination from "@comp/customPagination";
import _ from "lodash"; // lodash工具库
import Swiper, { Autoplay, Pagination, Loop } from "swiper";
import "swiper/swiper.min.css";
Swiper.use([Autoplay, Pagination, Loop]);

export default {
  components: { customPagination },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      query_word: "",
      bannerSwiper: null,
      bannerload: false,
      banner_list: [],
      dataLoad: false,
      listData: [],
      listPage: {
        pageIndex: 1,
        pageSize: 12,
        total: 0,
      },
    };
  },
  created() {
    this.getData();
    this.getBanner();
  },
  methods: {
    /** 获取新闻列表 */
    async getData(dataLoad = true) {
      this.dataLoad = dataLoad;
      let params = {
        query_word: this.query_word,
        pageindex: this.listPage.pageIndex,
        pagesize: this.listPage.pageSize,
      };
      let { data: res } = await news.stuinformationList(params);
      this.dataLoad = false;
      this.listData = res.data;
      this.listPage.total = res.allcount;
    },
    /** 获取banner数据 */
    async getBanner() {
      let { data } = await news.get_banner_list();
      this.banner_list = data;
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    /** 实例化swiper */
    initSwiper() {
      this.bannerSwiper = new Swiper(".banner-swiper", {
        speed: 1200,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: true,
        },
        pagination: {
          el: ".banner-pagination",
          clickable: true,
          bulletClass: "banner-bullet",
          bulletActiveClass: "banner-bullet-active",
        },
        preventClicks: true,
      });
    },
    /**
     * banner跳转
     * @param {object} item banner信息
     */
    targetLink(item) {
      let type = item.plban_herf_type;
      let url = item.plban_herf;
      if (url) {
        switch (type) {
          case 10:
            this.$router.push({ path: url });
            break;
          case 20:
            window.open(url, "_blank");
            break;
          default:
            break;
        }
      }
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 筛选数据 */
    searchData() {
      this.listData = [];
      this.listPage.pageIndex = 1;
      this.getData();
    },
    /** 点赞、取消点赞 */
    likeNews: _.throttle(function (plinf_id) {
      this.handleLike(plinf_id);
    }, 500),
    async handleLike(plinf_id) {
      let res = await news.add_praise({ plinf_id });
      if (res) {
        this.getData(false);
      }
    },
  },
};
</script>